@import "common";

.help_bubble {
    position: absolute;
    padding: 30px 30px 30px;
    background: white;
    box-shadow: 3px 40px 40px rgba(0, 0, 0, 0.3);
    z-index: 99998;
    color: #222;
    border: solid 1px $darkSelectionColor;
    border-radius: 3px;

    .innerFrame {
        background: $darkSelectionColor;
    }

    .buttons {
        width: 100%;
        height: 30px;
        margin-top: 30px;
        text-align: center;

        .button {
            background: $selectionColor;
            color: white;
            pointer-events: auto;
            cursor: pointer;
            font-size: 15px;
            font-weight: 700;
            padding: 8px 18px;
            line-height: normal;
            text-align: center;
            outline: none;
            text-transform: uppercase;
            @include no_user_select;
            display: inline-block;

            &:hover {
                background: $darkSelectionColor;
            }

            margin-right: 20px;

            &:last-of-type {
                margin-right: 0px;
            }

            &.simple {
                background: #d9e1e3;
                color: #555;
            }
        }
    }

    .action-button {
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        display: inline-block;
        padding: 12px 20px;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        background: $darkSelectionColor;
        color: white;
        cursor: pointer;
        text-shadow: none;
    }

    img {
        position: absolute;
        top: 17px;
        left: 19px;
        width: 50px;
        height: 50px;
    }

    .title {
        font-size: 20px;
        text-transform: uppercase;
        letter-spacing: 0.75px;
        font-weight: 700;
        margin-left: 57px;
    }

    .body {
        margin-left: 57px;
        font-size: 20px;
        font-weight: 500;
        color: black;
        max-width: 550px;

        h4 {
            margin-top: -6px;
            font-size: 26px;
            margin-bottom: 20px;
        }

        p {
            margin-bottom: 20px;
        }

        p:last-of-type {
            margin-bottom: 0px;
        }
    }

    .bubbleArrow {
        position: absolute;
        background: white;
        width: 20px;
        height: 20px;
        transform: rotate(45deg);
    }

    .bubbleArrowBorder {
        background: $darkSelectionColor;
    }

    &.simple {
        padding: 12px 20px;

        .body {
            margin-left: 0px;
            font-size: 16px;
        }
    }

    &.add-team {
        max-width: 400px;

        h4 {
            font-size: 20px;
        }
    }

    &.library-slides {
        max-width: 550px;

        h4 {
            font-size: 20px;
        }
    }

    &.team-theme {
        max-width: 520px;

        h4 {
            font-size: 20px;
        }
    }

    &.workspace-select {
        body {
            margin-left: 0;
        }

        h4 {
            font-size: 20px;
        }

        p {
            font-size: 16px;
        }
    }
}
